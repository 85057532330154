import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

// Import views
import HomeView from '@/views/HomeView.vue'
import InscricaoView from '@/views/InscricaoView.vue'

let routes = [
  { 
    path: '/', 
    component: HomeView, 
  },
  {
    path: '/:slug',
    component: InscricaoView
  },
  {
    path: '/:slug/:id',
    component: InscricaoView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
