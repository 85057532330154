<template>
  <v-app style="background-color: #23221b !important;">
    <router-view @insertVisit="insertVisit()"></router-view>
  </v-app>
</template>

<script>
import axios from 'axios'
import config from '@/store/config';

const api = axios.create({
    baseURL: config.baseURL
});
export default {
  name: 'App',

  components: {
  },

  data(){
    return {
      edicao: {},
      uaToBlock: ["360Spider","acapbot","acoonbot","ahrefs","alexibot","asterias","attackbot","backdorbot","becomebot","binlar","blackwidow","blekkobot","blexbot","blowfish","bullseye","bunnys","butterfly","careerbot","casper","checkpriv","cheesebot","cherrypick","chinaclaw","choppy","clshttp","cmsworld","copernic","copyrightcheck","cosmos","crescent","cy_cho","datacha","demon","diavol","discobot","dittospyder","dotbot","dotnetdotcom","dumbot","emailcollector","emailsiphon","emailwolf","exabot","extract","eyenetie","feedfinder","flaming","flashget","flicky","foobot","g00g1e","getright","gigabot","go-ahead-got","gozilla","grabnet","grafula","harvest","heritrix","httrack","icarus6j","jetbot","jetcar","jikespider","kmccrew","leechftp","libweb","linkextractor","linkscan","linkwalker","loader","masscan","miner","majestic","mechanize","mj12bot","morfeus","moveoverbot","netmechanic","netspider","nicerspro","nikto","ninja","nutch","octopus","pagegrabber","planetwork","postrank","proximic","purebot","pycurl","python","queryn","queryseeker","radian6","radiation","realdownload","rogerbot","scooter","seekerspider","semalt","siclab","sindice","sistrix","sitebot","siteexplorer","sitesnagger","skygrid","smartdownload","snoopy","sosospider","spankbot","spbot","sqlmap","stackrambler","stripper","sucker","surftbot","sux0r","suzukacz","suzuran","takeout","teleport","telesoft","true_robots","turingos","turnit","vampire","vikspider","voideye","webleacher","webreaper","webstripper","webvac","webviewer","webwhacker","winhttp","wwwoffle","woxbot","xaldon","xxxyy","yamanalab","yioopbot","youda","zeus","zmeu","zune","zyborg","HeadlessChrome"]
    }
  },
  async mounted(){
    await this.insertVisit()
  },
  watch: {
    async '$route' () {
      await this.$vuetify.goTo(0);
      this.insertVisit()
    }
  },
  methods:{
    async analisarUserAgent(){
      console.log('Analizando User Agents')
      let ua = navigator.userAgent
      for (let i = 0; i < this.uaToBlock.length; i++) {
        if(ua.includes(this.uaToBlock[i])){
          return true
        }
      }

      return false
    },
    async retornaProximaEdicao(){
      try {
        let slug = this.$route.params.slug;
        if(!slug){
          return
        }
        const response = await api.get(`/edicao/${slug}`);
        this.edicao = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async insertVisit() {
      try {
        await this.retornaProximaEdicao()
        if(await this.analisarUserAgent()){
          window.location.href = "https://www.google.com"
          return
        }
        var ipdata = {}
        if(localStorage.getItem('fingerprint')){
          ipdata = JSON.parse(localStorage.getItem('fingerprint'))
        }else{
          let response = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=4afbf62ff3024a9f9e8e3e22ef334955")
          ipdata = response.data
          localStorage.setItem('fingerprint', JSON.stringify(ipdata))
        }

        let localstorage = ''
        if(this.edicao.id){ 
          let participante = localStorage.getItem(`participante-${this.edicao.id}`)
          if(participante){
            localstorage = JSON.parse(participante)
          }
        }

        let ua = `${ipdata.city}/${ipdata.region} - ${navigator.userAgent}`
        let fingerprint = {
          ip: ipdata.ip_address, 
          ua,
          path: window.location.href,
          refferer: document.referrer,
          localstorage: JSON.stringify(localstorage, null, 2),
        }

        console.log(fingerprint)
        if(window.location.href.includes('localhost')){
          return
        }
        await api.post('/visita', fingerprint)
      } catch (error) {
        console.error(error)
      }
    },
  }
};
</script>
