<template>
    <v-app class="bg-app">
        <v-container fluid class="container-acabana">
            <v-row>
                <v-col cols="12" class="logo-top">
                    <img :src="logo" alt="">
                    <h1 class="text-center">INSCRIÇÕES ABERTAS!</h1>
                    <p>Links de inscrição para os programas da CABANA.</p>
                </v-col>
            </v-row>
            <v-row class="lista-cabanas">
                <v-col cols="12" md="6" offset-md="3" v-for="(e, index) in edicoes" :key="index">
                    <div class="cabana">
                        <a href="" @click.prevent="goTo(e.slug)">
                            <h2 class="text-center">EDIÇÃO {{ e.cidade }}, {{ e.estado }} <br><div class="data">{{ e.dataEscrita }}</div></h2>
                        </a>
                    </div>
                </v-col>
            </v-row>
        <RodapePage></RodapePage>
    </v-container>


        <div class="fill" v-if="loading">
            <div class="loading">
                <img :src="logo" alt="">
            </div>
        </div>


    </v-app>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import config from '@/store/config';
import moment from 'moment';
import RodapePage from '@/components/RodapePage.vue';

moment.locale('pt-br');

const api = axios.create({
    baseURL: config.baseURL
});
export default {
    name: 'HomeView',
    data() {
        return {
            loading: true,
            logo: require('@/assets/logo.png'),
            valorTeste: false,
            edicoes: []
        }
    },
    components: {
        RodapePage
    },
    async mounted() {
        await this.init()
    },
    methods: {
        async init(){
            await this.listaEdicoes();
        },
        async listaEdicoes(){
            try {
                const response = await api.get('/edicoes');
                this.edicoes = response.data;

                this.edicoes.forEach(e => {
                    let dataInicio = moment(e.data_inicio).format('DD');
                    let dataFim = moment(e.data_fim).format('DD');
                    let mesEscritoInicio = moment(e.data_inicio).format('MMMM');
                    let mesEscritoFim = moment(e.data_fim).format('MMMM');
                    let ano = moment(e.data_inicio).format('YYYY');

                    
                    if(mesEscritoInicio == mesEscritoFim){
                        e.dataEscrita = `${dataInicio} a ${dataFim} de ${mesEscritoInicio} de ${ano}`;
                    }else{
                        e.dataEscrita = `${dataInicio} de ${mesEscritoInicio} a ${dataFim} de ${mesEscritoFim} de ${ano}`;
                    }
                });

                this.loading = false;
            } catch (error) {
                console.log(error);
            }
        },
        async goTo(slug){
            if(slug == 'belem'){
                window.location.href = 'https://www.acabananorte.com.br/in%C3%ADcio'
                return
            }
            this.$router.push(`/${slug}`);
        },
       
    }
};
</script>

<style scoped>
    .bg-app {
        background: url('@/assets/img/fundo.png') no-repeat center center fixed;
    }

    .container-acabana{
        background-color: rgba(0, 0, 0, 0.705);
        padding-bottom: 200px;
    }

    .logo-top{
        text-align: center;
    }

    .logo-top img{
        width: 140px;
        display: block;
        margin: 0 auto;
        margin-top: 50px;
    }
    
    .logo-top h1{
        color: white;
        font-size: 30px ;
        margin-top: 20px;
    }

    .logo-top p{
        color: white;
        font-size: 16px !important;
    }

    h2{
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
    }

    h2 .data{
        font-size: 14px;
        font-weight: 300;
        text-transform: uppercase
    }
    
    .logo-top h1{
        color: white;
        font-size: 30px;
        margin-top: 20px;
    }

    .logo-top p{
        color: white;
        font-size: 20px;
    }

    .text-center{
        text-align: center;
    }
    

    .cabana{
        height: 100%;
        z-index: 0;
        overflow: hidden;
        transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99), color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99), border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99), transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99), background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
        border: 1px solid rgb(255, 255, 255);
        background-color: transparent;
        color: rgb(255, 255, 255);
        border-radius: 30px;
    }

    .cabana a{
        container: link-button / inline-size;
        hyphens: auto;
        white-space: normal;
        background: none;
        color: inherit;
        transition: box-shadow 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99), border-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99), transform 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99), background-color 0.25s cubic-bezier(0.08, 0.59, 0.29, 0.99);
        overflow-wrap: break-word;
        word-break: break-word;
        padding-left: 66px;
        padding-right: 66px;
        min-height: 64px !important;
        margin: 0px;
        border: none;
        font-family: inherit;
        font-weight: inherit;
        font-size: inherit;
        text-align: center;
        cursor: pointer;
        background: none;
        text-decoration: none;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        height: auto;
        position: relative;
        padding: 11px 20px;
        width: 100%;
        appearance: none;
        box-sizing: border-box;
        vertical-align: middle;
    }

    .cabana:hover{
        background-color: rgb(255, 255, 255);
        color: #016E1A
    }


    .fill{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #23221bff;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    /* IMAGE BOUNCE SLOW */

    .loading img {
        display: block;
        width: 80%;
        margin: 0 auto;
        animation: bounce 2s infinite;
    }

    @media (max-width: 768px) {
        .loading img {
            width: 50%;
        }
    }

    @keyframes bounce {
        0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
        }
        40% {
            transform: translateY(-30px);
        }
        60% {
            transform: translateY(-15px);
        }
    }
</style>