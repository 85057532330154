import Vue from "vue";
import Vuex from "vuex";
// import axios from 'axios'
// import config from './config'
// const api = axios.create({
//     baseURL: config.baseURL
// })

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
   
  },
  mutations: {
    
  },
  actions: {
   
  },
  getters: {
   
    
  }
});
