import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@fortawesome/fontawesome-free/css/all.css'
import ZoomOnHover from "vue-zoom-on-hover";
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies'
import VueShortkey from 'vue-shortkey'


// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueShortkey)
Vue.use(VueSweetalert2);
Vue.use(ZoomOnHover);
Vue.config.productionTip = false
Vue.use(VueCookies, { expires: '7d'})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
